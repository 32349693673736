import { Fragment } from "react";

type Props = {
  className?: string;
  title: string | undefined;
};

export default function SymbolizedElement({
  title = "",
  className = "",
}: Props) {
  if (!title) {
    return <></>;
  }
  const titleParts = title.split("®");
  const symbolized = titleParts.map((part, idx) => {
    return (
      <Fragment key={idx}>
        <span className={className}>{part}</span>
        {idx !== titleParts.length - 1 && (
          <sup className="text-[.75em]">®</sup>
        )}
      </Fragment>
    );
  });
  return <>{symbolized}</>;
}
