"use client";
import { useState } from "react";

import Image from "next/image";

import { PhotoIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { getImageDimensions } from "shared-lib/helpers/utilityFunctions";

import { getRecipeImageURL } from "@/lib/helpers/backendFunctions";
import { getHoverGridVariant } from "@/lib/helpers/brandFunctions";

export default function RecipeImage({
  className = "",
  divClassName = "",
  recipe = {} as Recipe,
  urlSrc = "",
  priority = false,
  size = "medium",
  extraRound = false,
  noRounding = false,
  fullyRounded = false,
  landscape = false,
  videoRatio = false,
  imageRatio = false,
  noZoom = false,
  noWash = false,
  slowZoom = false,
}: {
  className?: string;
  divClassName?: string;
  recipe: Recipe | RecipePromo;
  urlSrc?: string;
  priority?: boolean;
  size?: "small" | "medium" | "large";
  extraRound?: boolean;
  noRounding?: boolean;
  fullyRounded?: boolean;
  landscape?: boolean;
  videoRatio?: boolean;
  imageRatio?: boolean;
  noZoom?: boolean;
  noWash?: boolean;
  slowZoom?: boolean;
}) {
  const [isLoading, setLoading] = useState(true);
  const [width, height] = getImageDimensions(size);
  const hoverGridVariant = getHoverGridVariant();
  const src = urlSrc || getRecipeImageURL(recipe, width, height);

  return (
    <>
      {src ? (
        <div
          className={clsx(
            "overflow-hidden",
            divClassName,
            fullyRounded
              ? "rounded-full"
              : extraRound
                ? "rounded-2xl"
                : noRounding
                  ? ""
                  : "rounded-md",
          )}
        >
          <Image
            key={recipe.title}
            priority={priority}
            src={src}
            alt="recipe image"
            width={width}
            height={height}
            className={clsx(
              className,
              "w-full object-cover ease-in-out",
              isLoading
                ? "scale-110 blur-2xl grayscale"
                : "scale-100 grayscale-0",
              extraRound ? "rounded-2xl" : noRounding ? "" : "rounded-md",
              fullyRounded && "aspect-square",
              !noWash && "group-hover:opacity-75",
              !noZoom &&
                [
                  "image_zoom",
                  "image_zoom_bold_text",
                  "image_zoom_background_color",
                ].includes(hoverGridVariant) &&
                "group-hover:scale-110",
              slowZoom ? "duration-500" : "duration-200",
              landscape && "h-[600px]",
              videoRatio && "aspect-video",
              imageRatio && "aspect-[4/3] h-24",
            )}
            onLoad={() => setLoading(false)}
            unoptimized
          />
        </div>
      ) : (
        <PhotoIcon
          className={clsx(className, "h-full w-full stroke-gray-400")}
        />
      )}
    </>
  );
}
