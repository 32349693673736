import Brands from "shared-lib/Brands";
import { brand } from "shared-lib/helpers/envFunctions";

export function getEzoicSrc() {
  return "//www.ezojs.com/ezoic/sa.min.js";
}

export function getHumixSrc() {
  return "https://www.humix.com/video.js";
}

export function getTitle(recipeTitle: string) {
  return recipeTitle || brand.name;
}

export function getHoverGridVariant() {
  switch (brand.id) {
    case Brands.cookingprofessionally: {
      return "image_zoom_bold_text";
    }
    case Brands.commandcooking: {
      return "image_zoom_bold_text";
    }
    case Brands.easiereats: {
      return "image_zoom";
    }
    case Brands.heartlandcooking: {
      return "image_zoom_background_color";
    }
    case Brands.recipereader: {
      return "image_zoom_background_color";
    }
    case Brands.recipesdirect: {
      return "image_zoom";
    }
    case Brands.recipesaver: {
      return "image_zoom_bold_text";
    }
    case Brands.lazycooking: {
      return "image_zoom_bold_text";
    }
    case Brands.mamascooking: {
      return "image_zoom_background_color";
    }
    default: {
      return "";
    }
  }
}
