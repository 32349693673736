export const EZOIC_RECIPE_PAGE_PLACEHOLDERS = [
  103, 106, 109, 115, 116, 117, 125,
];
export const EZOIC_RECIPE_PAGE_PLACEHOLDERS_MOBILE_ONLY = [
  118, 120, 121, 122, 123, 124,
];
export const EZOIC_RECIPE_PAGE_PLACEHOLDERS_DESKTOP_ONLY = [160];
export const EZOIC_RECIPE_PAGE_PLACEHOLDERS_DISPLAY_MORE = [119];
export const EZOIC_ARTICLE_PAGE_PLACEHOLDERS = [
  126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 154,
  155, 156, 157, 158,
];
export const EZOIC_ARTICLE_PAGE_PLACEHOLDERS_MOBILE_ONLY = [
  120, 121, 122, 123, 124,
];
export const EZOIC_ARTICLE_PAGE_PLACEHOLDERS_DESKTOP_ONLY = [];
export const EZOIC_HOME_PAGE_PLACEHOLDERS = [140, 141, 142, 143, 144, 145, 146];
export const EZOIC_HOME_PAGE_PLACEHOLDERS_MOBILE_ONLY = [];
export const EZOIC_HOME_PAGE_PLACEHOLDERS_DESKTOP_ONLY = [];
export const EZOIC_CATEGORY_PAGE_PLACEHOLDERS = [147, 149];
export const EZOIC_CATEGORY_PAGE_PLACEHOLDERS_MOBILE_ONLY = [
  150, 151, 152, 153,
];
export const EZOIC_CATEGORY_PAGE_PLACEHOLDERS_DESKTOP_ONLY = [148];
export const EZOIC_TAG_PAGE_PLACEHOLDERS = [147, 149];
export const EZOIC_TAG_PAGE_PLACEHOLDERS_MOBILE_ONLY = [150, 151, 152, 153];
export const EZOIC_TAG_PAGE_PLACEHOLDERS_DESKTOP_ONLY = [148];

export const EZOIC_MOBILE_PLACEHOLDERS = [
  ...EZOIC_RECIPE_PAGE_PLACEHOLDERS_MOBILE_ONLY,
  ...EZOIC_ARTICLE_PAGE_PLACEHOLDERS_MOBILE_ONLY,
  ...EZOIC_HOME_PAGE_PLACEHOLDERS_MOBILE_ONLY,
  ...EZOIC_CATEGORY_PAGE_PLACEHOLDERS_MOBILE_ONLY,
  ...EZOIC_TAG_PAGE_PLACEHOLDERS_MOBILE_ONLY,
];

export const EZOIC_DESKTOP_PLACEHOLDERS = [
  ...EZOIC_RECIPE_PAGE_PLACEHOLDERS_DESKTOP_ONLY,
  ...EZOIC_ARTICLE_PAGE_PLACEHOLDERS_DESKTOP_ONLY,
  ...EZOIC_HOME_PAGE_PLACEHOLDERS_DESKTOP_ONLY,
  ...EZOIC_CATEGORY_PAGE_PLACEHOLDERS_DESKTOP_ONLY,
  ...EZOIC_TAG_PAGE_PLACEHOLDERS_DESKTOP_ONLY,
];

export const EZOIC_COMMON_PLACEHOLDERS = [
  ...EZOIC_RECIPE_PAGE_PLACEHOLDERS,
  ...EZOIC_ARTICLE_PAGE_PLACEHOLDERS,
  ...EZOIC_HOME_PAGE_PLACEHOLDERS,
  ...EZOIC_CATEGORY_PAGE_PLACEHOLDERS,
  ...EZOIC_TAG_PAGE_PLACEHOLDERS,
];

export const IS_PRODUCTION_BUILD =
  process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true";
export const IS_CI_BUILD = process.env.NEXT_PUBLIC_IS_CI === "true";

export const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development";

export const LANDING_PAGE_PATHS = [
  "/1p",
  "/fall24hcswpv1",
  "/hexclad-ultimate-everything",
];

export enum PAGE_NAMES {
  ARTICLE = "article",
  CATEGORY = "category",
  RECIPE = "recipe",
  TAG = "tag",
  HOME = "home",
}

export const PAGES_WITH_ADS = Object.values(PAGE_NAMES);
