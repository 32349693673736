import { NextRouter } from "next/router";

import { brand } from "shared-lib/helpers/envFunctions";

export function getRecipeImageURL(
  recipe: Recipe,
  width = 0,
  height = 0,
  quality = "75",
) {
  const params: { [key: string]: string } = {
    fm: "webp",
    q: quality,
  };

  if (width && height) {
    Object.assign(params, {
      w: Math.floor(width),
      h: Math.floor(height),
      fit: "fill",
    });
  }

  const url = (recipe?.primaryImages?.items?.[0] ?? {}).url;

  if (url) {
    const paramsString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    const imageUrl = `${url}?${paramsString}`;
    if (url.startsWith("https:")) {
      return imageUrl;
    }
    return `https:${imageUrl}`;
  }

  return "";
}

export function getSquareThumbRecipeImageURL(
  recipe: Recipe,
  width = 0,
  height = 0,
  quality = "75",
) {
  const squareSize = width < height ? width : height;
  const params: { [key: string]: string } = {
    fm: "webp",
    q: quality,
  };

  if (width && height) {
    Object.assign(params, {
      w: Math.floor(squareSize),
      h: Math.floor(squareSize),
      fit: "thumb",
    });
  }

  const url = (recipe?.primaryImages?.items?.[0] ?? {}).url;

  if (url) {
    const paramsString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    const imageUrl = `${url}?${paramsString}`;
    if (url.startsWith("https:")) {
      return imageUrl;
    }
    return `https:${imageUrl}`;
  }

  return "";
}

export function getRecipeImageURLs(recipe: Recipe, width = 0, height = 0) {
  const params: { [key: string]: string } = {
    fm: "webp",
    q: "50",
  };

  if (height && width) {
    Object.assign(params, {
      w: Math.floor(width),
      h: Math.floor(height),
      fit: "fill",
    });
  }

  const primaryImage = recipe?.primaryImages?.items?.[0];
  const images = [primaryImage, ...(recipe?.otherImages?.items ?? [])].filter(
    Boolean,
  );

  const urls = images.map((image) => {
    if (image) {
      const url = image.url;

      if (url) {
        const paramsString = Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&");

        const imageUrl = `${url}?${paramsString}`;
        if (url.startsWith("https:")) {
          return imageUrl;
        }
        return `https:${imageUrl}`;
      }
    }

    return "";
  });

  return urls;
}

export function getFrontendSharingURL(router: NextRouter) {
  let url = router.asPath;
  const queryParamIndex = url.indexOf("?");
  if (queryParamIndex > -1) {
    url = url.substring(0, queryParamIndex);
  }
  return brand.baseUrl + url;
}

export function getFrontendSharingURL_App(pathName: string) {
  return brand.baseUrl + pathName;
}
