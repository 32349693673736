const getItemLink = (
  path: string,
  i: Category_Recipe | Recipe | Tag | RecipePromo,
) => path + i.slug;

export const getCategoryLink = (i: Category_Recipe) =>
  getItemLink("/category/", i);
export const getTagLink = (i: Tag) => getItemLink("/tag/", i);
export const getRecipeLink = (i: Recipe | RecipePromo) =>
  getItemLink("/recipe/", i);
